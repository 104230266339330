import { ContainerMaxWidth } from '@hypersay/ui';
import React from 'react';

import HowToHypersayMenu from '../../components/howToHypersayMenu';
import SEO from '../../components/seo';
import T from '../../components/T';
import LayoutBlog from '../../layouts/blog';

import { withPrefix } from 'gatsby';
import * as styles from './howtohypersay.module.scss';

const WorkRemotely = () => (
  <LayoutBlog>
    <SEO
      title="Hypersay Blog - How to work remotely with Hypersay"
      description="Read articles on how to build interactive presentations and how to use Hypersay effectively."
      keywords={[
        'work remotely',
        'live presentation',
        'blog',
        'interactive presentations',
        'townhall meetings',
        'work',
        'work from home',
      ]}
    />
    <ContainerMaxWidth className={styles.container} maxWidth="1400px">
      <T tagName="h1" id="Hypersay for Working Remotely" />
      <T
        tagName="p"
        id="Our 180K users enjoy Hypersay for very different reasons, based on their professions. Below you can find several use cases."
      />
      <HowToHypersayMenu active="work-remotely" />
      <div className={styles.item}>
        <div className={styles.image}>
          <img
            src={withPrefix('/images/blog/work-from-home-bubble.png')}
            alt="questions"
          />
        </div>
        <div className={styles.text}>
          <T tagName="h2" id="Run remote presentations" />
          <T tagName="p" id="Client pitches, product presentations, and/or town hall updates need to be impressive even if the current climate requires them to be delivered remotely." />
          <T tagName="p" id="Hypersay allows you to engage your clients, gives them a voice to speak to you through the Question Wall (no interruptions) and keeps everyone on the same page with synchronised paging. If you cannot be in the same room, Hypersay allows you to deliver powerful presentations to a digitally connected audience." />
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.text} style={{ maxWidth: '700px' }}>
          <T tagName="h2" id="Steps to take" />
          <ul>
            <T tagName="li" id="Upload your slides to Hypersay and add the desired interactions" />
            <T tagName="li" id="During the video conferencing, share the Hypersay session code" />
            <T tagName="li" id="Ask your audience to join the session using their phones or open another tab in their browser" />
            <T tagName="li" id="Encourage your audience to use the Question Wall rather than interrupt your presentation" />
            <T tagName="li" id="Share your own screen so that the attendees can see the results of the polls/quizzes/interactions as you speak" />
          </ul>

          <T tagName="p" id="Do not waste time answering:  “Which slide are we on!?”" />
          <T tagName="p" id="Stay safe and tech-cool in one fell swoop." />
        </div>
        {/* <div className={styles.image}>
          <img src={withPrefix('/images/use-cases/share.png')} alt="share" />
        </div> */}
        <iframe width="560" height="315" src="https://www.youtube.com/embed/4N1Eo_LY6U0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </ContainerMaxWidth>
  </LayoutBlog>
);

export default WorkRemotely;
